@import '../../sass/variables.scss';

.main--index {
  padding: 86px 16px 12px;

  @media (min-width: $mobile-width) {
    padding: 92px 20px 16px;
  }

  @media (min-width: $tablet-width) {
    padding: 100px 20px 40px;
  }

  @media (min-width: $desktop-width) {
    padding-left: 100px;
    padding-right: 100px;
  }
}

.error-message {
  text-align: center;
  margin: 0;
  margin-bottom: 28px;
  font-size: 14px;
  line-height: 23px;
  color: $error-color;

  @media (min-width: $mobile-width) {
    margin-bottom: 38px;
    font-size: 16px;
    line-height: 26px;
  }

  @media (min-width: $tablet-width) {
    margin-bottom: 90px;
    font-size: 18px;
    line-height: 23px;
  }
}
