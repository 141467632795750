@import '../../sass/variables.scss';

.result__sentence {
  padding-left: 10px;
  position: relative;

  &:last-child {
    margin-bottom: 40px;
  }

  @media (min-width: $mobile-width) {
    font-size: 18px;
    line-height: 23px;
    padding-left: 14px;
  }

  @media (min-width: $tablet-width) {
    font-size: 24px;
    line-height: 31px;
    padding-left: 18px;
  }
}

.result__item:last-child .result__sentence:last-child {
  margin-bottom: 0;
}

.result__search-word--examples {
  margin-bottom: 12px;

  @media (min-width: $tablet-width) {
    margin-bottom: 16px;
  }
}
