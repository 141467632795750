@import '../../sass/variables.scss';

.result {
  padding: 20px 0;

  &:not(:last-child) {
    border-bottom: 1px solid $basic-text-color;
  }

  @media (min-width: $mobile-width) {
    padding: 28px 0;
  }

  @media (min-width: $tablet-width) {
    padding: 40px 0;
  }
}

.result__title {
  font-size: 20px;
  line-height: 26px;
  margin: 0;
  font-weight: 700;

  @media (min-width: $mobile-width) {
    font-size: 26px;
    line-height: 34px;
  }

  @media (min-width: $tablet-width) {
    font-size: 32px;
    line-height: 41px;
  }
}

.result__list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
