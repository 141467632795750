@font-face {
  font-family: 'PT Sans Caption';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../fonts/ptsans-caption-regular.woff') format('woff');
}

@font-face {
  font-family: 'PT Sans Caption';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../fonts/ptsans-caption-bold.woff') format('woff');
}
