// colors

$basic-color: #029dcd;
$basic-dark: #2561a7;
$basic-text-color: #525252;
$error-color: #ac3e3e;

//viewports

$mobile-width: 481px;
$tablet-width: 768px;
$desktop-width: 1200px;
