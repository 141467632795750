@import '../../sass/variables.scss';

.stem__item {
  margin-top: 12px;

  @media (min-width: $tablet-width) {
    margin-top: 16px;
  }
}

.stem__word {
  margin: 0;
  color: $basic-color;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  padding-left: 10px;
  position: relative;

  @media (min-width: $mobile-width) {
    font-size: 18px;
    line-height: 23px;
    padding-left: 14px;
  }

  @media (min-width: $tablet-width) {
    font-size: 24px;
    line-height: 31px;
    padding-left: 18px;
  }
}

.result__list-style-arrow::before {
  color: $basic-text-color;
  position: absolute;
  left: -3px;
  top: 0;
  bottom: 0;
  content: '▸';
}

.stem__transcription {
  margin: 0;
}
