#root {
  height: 100%;
}

html {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  font-family: 'PT Sans Caption', sans-serif;
  font-weight: 400;
  color: #000000;
  background-color: #ffffff;
}

img {
  max-width: 100%;
  max-height: 100vh;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.visually-hidden {
  width: 1px;
  height: 1px;
  position: absolute;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}
