@import '../../sass/variables.scss';

.app {
  min-width: 320px;
  max-width: 1200px;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.25);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  color: #ffffff;
  background-color: $basic-color;
}

.main {
  flex-grow: 1;
}

.header {
  padding: 16px;
  padding-bottom: 26px;
  position: relative;
  overflow: hidden;

  @media (min-width: $mobile-width) {
    padding: 28px 40px 42px;
  }

  @media (min-width: $tablet-width) {
    padding: 43px 81px 74px;
  }

  @media (min-width: $desktop-width) {
    padding: 43px 165px 91px;
  }
}

.header::before {
  background-color: $basic-dark;
  content: '';
  width: 200%;
  height: 510%;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  clip-path: ellipse(closest-side farthest-side);
}

.logo {
  width: 35px;
  height: 35px;
  position: absolute;
  z-index: 2;

  @media (min-width: $mobile-width) {
    transform: translateY(6px);
    width: 50px;
    height: 50px;
  }

  @media (min-width: $tablet-width) {
    transform: translateY(0);
    width: 70px;
    height: 70px;
  }

  &:hover {
    opacity: 70%;
  }

  &:active {
    opacity: 30%;
  }
}

.logo__image {
  width: 100%;
  height: auto;
}

.header__title {
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  margin: 0 auto;
  margin-top: 4px;
  text-align: center;
  color: #ffffff;
  max-width: 208px;
  position: relative;
  z-index: 2;

  @media (min-width: $mobile-width) {
    font-size: 18px;
    line-height: 23px;
    max-width: 268px;
  }

  @media (min-width: $tablet-width) {
    font-size: 24px;
    line-height: 31px;
    max-width: 360px;
  }
}

.footer {
  padding: 14px 34px 15px;
  background-color: $basic-dark;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr auto;
  row-gap: 12px;
  column-gap: 12px;

  @media (min-width: $mobile-width) {
    padding: 24px;
    row-gap: 16px;
    column-gap: 18px;
  }

  @media (min-width: $tablet-width) {
    padding: 44px 28px 28px;
    row-gap: 12px;
    column-gap: 26px;
  }

  @media (min-width: $desktop-width) {
    padding: 44px 44px 28px;
    row-gap: 10px;
  }
}

.social {
  margin-left: 2px;
  grid-area: 1/1/2/2;
  justify-self: end;
}

.social__list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
}

.social__item {
  margin: 4px 6px;

  @media (min-width: $mobile-width) {
    margin: 8px 8px;
  }

  @media (min-width: $tablet-width) {
    margin: 10px 13px;
  }
}

.social__link {
  width: 26px;
  height: 26px;
  display: block;
  position: relative;

  @media (min-width: $mobile-width) {
    width: 34px;
    height: 34px;
  }

  @media (min-width: $tablet-width) {
    width: 40px;
    height: 40px;
  }
}

.social__icon {
  width: 100%;
  height: auto;
  position: absolute;
  fill: #ffffff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
}

.social__link:hover .social__icon {
  fill: #6fc8ff;
}

.social__link:active .social__icon {
  fill: #98abc1;
}

.copyright {
  font-size: 10px;
  line-height: 13px;
  grid-area: 2/1/3/-1;
  margin: 0;
  align-self: center;
  justify-self: center;

  @media (min-width: $mobile-width) {
    font-size: 14px;
    line-height: 18px;
  }

  @media (min-width: $tablet-width) {
    font-size: 20px;
    line-height: 26px;
  }
}

.navigation {
  grid-area: 1/-2/2/-1;
  align-self: center;
  margin-right: 5px;
}

.navigation__list {
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(124px, 1fr));
  gap: 10px;
}

.navigation__link {
  color: white;
  font-size: 14px;
  line-height: 18px;

  @media (min-width: $mobile-width) {
    font-size: 20px;
    line-height: 26px;
  }

  @media (min-width: $tablet-width) {
    font-size: 24px;
    line-height: 31px;
  }

  &:hover {
    color: #6fc8ff;
  }

  &:active {
    color: #98abc1;
  }
}
